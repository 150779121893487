import dayjs from 'dayjs';
import { setHrAxiosLocale } from '@helloworld1812/hr-api';
import { WsLanguage, i18n, initI18n as initWsI18n } from '@helloworld1812/ws-i18n';
import { WsDeployEnv } from '@helloworld1812/ws-env';

import 'dayjs/locale/fr';

import { queryClient } from './non-business-app';

export const initI18n = () => {
  const isProduction = process.env.DEPLOY_ENV === 'production';

  initWsI18n({
    wsDeployEnv: process.env.DEPLOY_ENV as WsDeployEnv,
    locizeApiKey: isProduction ? '' : '50c6c853-4033-4061-b1f2-7d238e630fd0',
    supportedLngs: ['en', 'fr'],
    onInitialized: () => {
      dayjs.locale(i18n.resolvedLanguage ?? 'en');
      setHrAxiosLocale(i18n.resolvedLanguage ?? 'en');
    },
    onLanguageChanged: (language) => {
      dayjs.locale(language);
      setHrAxiosLocale(language);

      queryClient.invalidateQueries();
    },
  });
};

export type Language = WsLanguage;
